// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// NewUser page components
import FormField from "../FormField";

import SoftSelect from "softMui/SoftSelect";
import SoftDatePicker from "softMui/SoftDatePicker";

function PersonalInfo({ formData }) {
  const { values, errors, touched, setFieldValue } = formData;
  const {
    names,
    surnames,
    gender,
    dateOfBirth,
    civilStatus,
    socialSecurityNumber,
    curp,
    rfc,
    cellphone,
    phone,
    email,
  } = formData.formField;

  return (
    <>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="bold" color="text">
          Información personal
        </SoftTypography>
      </SoftBox>
      <SoftBox my={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={names.type}
              label={names.label}
              name={names.name}
              value={values.names}
              placeholder={names.placeholder}
              error={errors.names && touched.names}
              success={values.names.length > 0 && !errors.names}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={surnames.type}
              label={surnames.label}
              name={surnames.name}
              value={values.surnames}
              placeholder={surnames.placeholder}
              error={errors.surnames && touched.surnames}
              success={values.surnames.length > 0 && !errors.surnames}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {gender.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={gender.name}
                    placeholder={gender.placeholder}
                    defaultValue={values.gender ?? null}
                    options={[
                      { value: "M", label: "Masculino" },
                      { value: "F", label: "Femenino" },
                      { value: "NB", label: "No binario" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {dateOfBirth.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker
                    name={dateOfBirth.name}
                    placeholder={dateOfBirth.placeholder}
                    value={values.dateOfBirth}
                    onChange={(newDate) =>
                      setFieldValue(`${dateOfBirth.name}`, newDate)
                    }
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {civilStatus.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={civilStatus.name}
                    placeholder={civilStatus.placeholder}
                    defaultValue={values.civilStatus ?? null}
                    options={[
                      { value: "S", label: "Soltero" },
                      { value: "C", label: "Casado" },
                      { value: "V", label: "Viudo" },
                      { value: "UL", label: "Unión libre" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type={socialSecurityNumber.type}
                    label={socialSecurityNumber.label}
                    name={socialSecurityNumber.name}
                    value={values.socialSecurityNumber}
                    placeholder={socialSecurityNumber.placeholder}
                    error={
                      errors.socialSecurityNumber &&
                      touched.socialSecurityNumber
                    }
                    // success={values.socialSecurityNumber.length > 0 && !errors.socialSecurityNumber}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type={cellphone.type}
                    label={cellphone.label}
                    name={cellphone.name}
                    value={values.cellphone}
                    placeholder={cellphone.placeholder}
                    error={errors.cellphone && touched.cellphone}
                    // success={values.cellphone.length > 0 && !errors.cellphone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type={phone.type}
                    label={phone.label}
                    name={phone.name}
                    value={values.phone}
                    placeholder={phone.placeholder}
                    error={errors.phone && touched.phone}
                    // success={values.phone.length > 0 && !errors.phone}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={values.email}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              // success={values.email.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={curp.type}
              label={curp.label}
              name={curp.name}
              value={values.curp}
              placeholder={curp.placeholder}
              error={errors.curp && touched.curp}
              // success={values.curp.length > 0 && !errors.curp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={rfc.type}
              label={rfc.label}
              name={rfc.name}
              value={values.rfc}
              placeholder={rfc.placeholder}
              error={errors.rfc && touched.rfc}
              // success={values.rfc.length > 0 && !errors.rfc}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

// typechecking props for PersonalInfo
PersonalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PersonalInfo;
