// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { AppBar, Tabs, Tab } from "@mui/material/";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";

function PricingTabs({ tabValue, tabHandler }) {
  return (
    <SoftBox width="100%" mt={1}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={tabHandler}>
          <Tab
            id="monthly"
            label={
              <SoftBox py={0.5} px={2}>
                MONTHLY
              </SoftBox>
            }
          />
          <Tab
            id="yearly"
            label={
              <SoftBox py={0.5} px={2}>
                YEARLY
              </SoftBox>
            }
          />
        </Tabs>
      </AppBar>
    </SoftBox>
  );
}

// Typechecking props for the PricingTabs
PricingTabs.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default PricingTabs;
