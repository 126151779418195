// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";
import SoftBadge from "softMui/SoftBadge";
import SoftButton from "softMui/SoftButton";
import PricesInfo from "../Prices";

function PricesPublicityCard({
  color,
  image,
  title,
  description,
  badge,
  action,
  plans,
}) {
  const template = (
    <Card
      sx={({
        functions: { rgba, linearGradient },
        palette: { gradients },
        borders: { borderRadius },
      }) => ({
        backgroundImage: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.8),
              rgba(gradients[color].state, 0.8)
            )}, url(${image})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.lg,
      })}
    >
      <SoftBox textAlign="left" pt={4} px={3} lineHeight={1}>
        <SoftTypography
          variant="h6"
          fontWeight="bold"
          color="white"
          gutterBottom
        >
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox textAlign="center" py={3} px={7} lineHeight={1}>
        <SoftBox mb={3}>
          <SoftTypography variant="body2" color="white">
            {description}
          </SoftTypography>
        </SoftBox>
        <PricesInfo plans={plans} />
        {badge && (
          <SoftBadge
            variant="rounded"
            size="sm"
            badgeContent={
              <SoftButton
                color="light"
                size="small"
                variant="outlined"
                sx={{ px: 1.5 }}
              >
                {badge}
              </SoftButton>
            }
            container
          />
        )}
      </SoftBox>
    </Card>
  );

  return action.type === "external" ? (
    <MuiLink href={action.route} target="_blank" rel="noreferrer">
      {template}
    </MuiLink>
  ) : (
    <Link to={action.route}>{template}</Link>
  );
}

// Setting default values for the props of PricesPublicityCard
PricesPublicityCard.defaultProps = {
  color: "dark",
  badge: "",
  action: false,
};

// Typechecking props for the PricesPublicityCard
PricesPublicityCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  badge: PropTypes.string,
  action: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  plans: PropTypes.arrayOf([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default PricesPublicityCard;
