import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import SoftBox from "softMui/SoftBox";

import { Grid } from "@mui/material";

// Employee components
import HeaderProfile from "../components/HeaderProfile";
import Header from "../components/Header";
import UserManagementCard from "./components/UserManagementCard";
import data from "./data";

function UserManagement() {
  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={12}>
            <HeaderProfile />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SoftBox mt={4}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Información personal"
                    info={data.personalInfo}
                    action={{ route: "", tooltip: "Edit personal info" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Información de empleado"
                    info={data.employeeInfo}
                    action={{ route: "", tooltip: "Edit employee info" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Educación"
                    info={data.education}
                    action={{ route: "", tooltip: "Edit education info" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Experiencia"
                    info={data.experience}
                    action={{ route: "", tooltip: "Edit experience info" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Información bancaria"
                    info={data.bankInfo}
                    action={{ route: "", tooltip: "Edit bank info" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserManagementCard
                    title="Personas de contacto"
                    info={data.emergencyContact}
                    action={{ route: "", tooltip: "Edit emergency contact info" }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default UserManagement;
