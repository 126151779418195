export const UI_STATES = Object.freeze({
  IS_VIEWING: "viewing",
  IS_EDITING: "editing",
  IS_CREATING: "creating",
});

export const API_ACTIONS = Object.freeze({
  INIT: "",
  IS_LOADING: "loading",
  IS_DELETING: "deleting",
  IS_SAVING: "saving",
  IS_UPDATING: "updating",
  IS_SEARCHING: "searching",
  IS_SELECTED: "selected",
});

export const MESSAGE_TYPES = Object.freeze({
  INIT: "",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  NEW_DATA: "new_data",
});

export const CLIENT_ID = "web-plat-cli";
