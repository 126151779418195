// Soft UI Dashboard PRO React example components
import PricesPublicityCard from "./PricesPublicityCard";

// images
import bgTeam from "assets/images/equipo-jovenes-empresarios-reunion.jpg";

function PlansPublicity() {
  return (
    <PricesPublicityCard
      title="Hola, DeSiCi!"
      image={bgTeam}
      description="Conoce todos los planes disponibles que tenemos para ti con las mejores tarifas"
      action={{
        type: "internal",
        route: "/pages/account/accounts",
      }}
      badge="cambia tu plan"
      plans={[
        { label: "STARTER", value: 59, currency: "$", color: "primary" },
        { label: "PREMIUM", value: 89, currency: "$", color: "primary" },
        { label: "ENTERPRISE", value: 99, currency: "$", color: "primary" },
      ]}
    />
  );
}

export default PlansPublicity;
