import Editions from "../items/Editions";

const adminRoutes = [
  {
    key: "configurations-admin-editions",
    route: "/account/configurations/admin/editions",
    component: <Editions />,
  },
];

export default adminRoutes;
