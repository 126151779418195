import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    edition,
    monthlyPrice,
    annualPrice,
    employeeLimit,
    emailLimit,
    spaceLimit,
    adminLimit,
    areasLimit,
    templatesLimit,
    vacanciesLimit,
    currentsLimit,
    interviewsLimit,
    evaluationsLimit,
    emailHelp,
    chatHelp,
    phoneHelp,
    smtpFeature,
    slaHelp,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [edition.name]: Yup.string().required(edition.errorMsg),
    [monthlyPrice.name]: Yup.number().required(monthlyPrice.errorMsg),
    [annualPrice.name]: Yup.number().required(annualPrice.errorMsg),
    [employeeLimit.name]: Yup.number().required(employeeLimit.errorMsg),
    [emailLimit.name]: Yup.number().required(emailLimit.errorMsg),
    [spaceLimit.name]: Yup.number().required(spaceLimit.errorMsg),
    [adminLimit.name]: Yup.number().required(adminLimit.errorMsg),
    [areasLimit.name]: Yup.number().required(areasLimit.errorMsg),
    [templatesLimit.name]: Yup.number().required(templatesLimit.errorMsg),
    [vacanciesLimit.name]: Yup.number().required(vacanciesLimit.errorMsg),
    [currentsLimit.name]: Yup.number().required(currentsLimit.errorMsg),
    [interviewsLimit.name]: Yup.number().required(interviewsLimit.errorMsg),
    [evaluationsLimit.name]: Yup.number().required(evaluationsLimit.errorMsg),
    [emailHelp.name]: Yup.boolean().required(emailHelp.errorMsg),
    [chatHelp.name]: Yup.boolean().required(chatHelp.errorMsg),
    [phoneHelp.name]: Yup.boolean().required(phoneHelp.errorMsg),
    [smtpFeature.name]: Yup.boolean().required(smtpFeature.errorMsg),
    [slaHelp.name]: Yup.string().required(slaHelp.errorMsg),
  }),
];

export default validations;
