import SoftAvatar from "softMui/SoftAvatar";
import SoftBadge from "softMui/SoftBadge";
import SoftButton from "softMui/SoftButton";

// Images
import desici from "assets/images/desici.jpg";

// @mui icons
import EditIcon from "@mui/icons-material/Edit";

function HeaderAvatar() {
  return (
    <SoftBadge
      color="white"
      variant="rounded"
      size="xs"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <SoftButton
          size="small"
          iconOnly={true}
          style={{ transform: "translate(-7px, -7px)" }}
        >
          <EditIcon />
        </SoftButton>
      }
    >
      <SoftAvatar
        src={desici}
        alt="profile-image"
        variant="rounded"
        size="xl"
        shadow="sm"
      />
    </SoftBadge>
  );
}

export default HeaderAvatar;
