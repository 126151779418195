
import { SignInKeyCloak, Callback } from "App/Modules/auth/sign-in/keycloak";
const authRoutes = [
  {
    key: "auth-sign-in",
    route: "/auth/sign-in/keycloak",
    component: <SignInKeyCloak />,
  },
  {
    key: "auth-sign-in-callback",
    route: "/callback",
    component: <Callback />,
  },
];

export default authRoutes;
