// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";
import SoftAvatar from "softMui/SoftAvatar";

// Soft UI Dashboard PRO React icons
import SpaceShip from "components/Icons/SpaceShip";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO React data variables
import currentPlanData from "./data/currentPlanData";

import labels from "./labels";

// Utils functions
import FormatDate from "utils/DateFormat";

function CurrentPlan() {
  const { borderWidth } = borders;

  const renderData = Object.entries(currentPlanData).map(([key, value]) => (
    <Grid item key={key}>
      <SoftBox height="100%" mt={0.5} lineHeight={1}>
        <SoftTypography variant="caption" display="block" fontWeight="light">
          {labels[key].label}
        </SoftTypography>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {labels[key].type === "date" ? FormatDate(value) : value}
        </SoftTypography>
      </SoftBox>
    </Grid>
  ));

  return (
    <Card sx={{ overflow: "scroll" }}>
      <SoftBox width="100%" pt={2} pb={2.5} px={2}>
        <Grid container pb={3} justifyContent="left" wrap="nowrap">
          <Grid item sx={{ flex: "0 0 4%" }}>
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="info"
              color="white"
              width="2rem"
              height="2rem"
              shadow="md"
              borderRadius="md"
              variant="gradient"
            >
              <Icon fontSize="small">{<SpaceShip color="white" />}</Icon>
            </SoftBox>
          </Grid>
          <Grid item>
            <SoftBox mt={0.75} lineHeight={1}>
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                Plan actual - <strong>Starter</strong>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          wrap="nowrap"
          spacing={2}
          px={6}
        >
          {renderData}
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default CurrentPlan;
