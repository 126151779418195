// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";

// Soft UI Dashboard React components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// WorkingHours page components
import Header from "../components/Header";
import WorkingHoursCard from "components/Cards/InfoCards/WorkingHours";
import horariosLaboralesData from "App/layouts/pages/profile/data/horariosLaboralesData";

function WorkingHours() {
  const horariosLaborales = horariosLaboralesData.map(({ title, description, actions }) => (
    <Grid item xs={12} md={12} xl={12} key={title}>
      <WorkingHoursCard title={title} description={description} actions={actions} />
    </Grid>
  ));

  return (
    <DashboardLayout>
      <Header />
        <SoftBox mt={5} mb={3} bgColor="white" sx={{ p: 3 }} borderRadius="xl">
          <Grid container spacing={3}>
            {horariosLaborales}
          </Grid>
        </SoftBox>
    </DashboardLayout>
  );
}

export default WorkingHours;
