import { useEffect, useState } from "react";

// @mui material components
import { Card, Grid, AppBar, Tabs, Tab } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import ContactDetailsForm from "./form";

function ContactDetails() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const renderTab =
    tabValue === 0 ? <ContactDetailsForm /> : <div>Other details</div>;

  const renderTabs = (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab id="contact-detail" label="Detalles de contacto" />
            <Tab id="other-details" label="Otros detalles" />
          </Tabs>
        </AppBar>
      </Grid>
    </Grid>
  );

  return (
    <Card id="contact-details">
      <SoftBox m={2} pb={5}>
        {renderTabs}
      </SoftBox>
      {renderTab}
    </Card>
  );
}

export default ContactDetails;
