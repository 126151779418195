import { useState } from "react";

// @mui material components
import { Grid, AppBar, Tabs, Tab } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";

// Soft UI Dashboard React components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// WorkingHours page components
import Header from "../components/Header";
import Tasks from "./components/Tasks";

function AccountNotifications() {
  const [tabValue, setTabValue] = useState(0);
  const [renderTab, setRenderTab] = useState(<h1>Building...</h1>);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    switch (event.currentTarget.id) {
      case "tareas":
        setRenderTab(<Tasks />);
        break;
      default:
        setRenderTab(<h1>Building...</h1>);
    }
  };

  return (
    <DashboardLayout>
      <Header />

      <SoftBox mt={5} mb={3} bgColor="white" sx={{ p: 3 }} borderRadius="xl">
        <Grid container pb={5}>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleSetTabValue}>
                <Tab id="tickets" label="Tickets" />
                <Tab id="problemas" label="Problemas" />
                <Tab id="cambios" label="Cambios" />
                <Tab id="liberaciones" label="Liberaciones" />
                <Tab id="activos" label="Activos" />
                <Tab id="contratos" label="Contratos" />
                <Tab id="tareas" label="Tareas" />
                <Tab id="ordenes-compra" label="Órdenes de compra" />
                <Tab id="proyectos" label="Proyectos" />
                <Tab id="hoarios-guardia" label="Horarios de guardia" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {renderTab}
      </SoftBox>
    </DashboardLayout>
  );
}

export default AccountNotifications;
