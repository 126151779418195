import checkout from "./form";

const {
  formField: {
    taskName,
    startAt,
    endAt,
    reminder,
    assigned,
    status,
    description,
  },
} = checkout;

const initialValues = {
  [taskName.name]: "",
  [startAt.name]: "",
  [endAt.name]: "",
  [reminder.name]: "",
  [assigned.name]: "",
  [status.name]: "",
  [description.name]: "",
};

export default initialValues;
