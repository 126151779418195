// @mui material components
import { Avatar, Card, Grid } from "@mui/material/";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// Soft UI Dashboard React components
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// Images
import bgURL from "assets/images/curved-images/curved15.jpg";
import HeaderAvatar from "App/layouts/pages/account/components/HeaderAvatar";

function Header() {
  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light={false} isMini={true}/>
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="12rem"
        borderRadius="none"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.light.main, 0.6),
              rgba(gradients.light.state, 0.6)
            )}, url(${bgURL})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          ml: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <HeaderAvatar />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                DeSiCi
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="light">
                Administrador
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
