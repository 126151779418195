// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import { Box } from "@mui/material";

function WorkingHoursCard({ title, description, actions }) {
  const { size } = typography;

  const buttonsActions = actions.map(({ route, tooltip, icon }) => (
    <>
      <SoftTypography
        key={`${icon}-button`}
        component={Link}
        to={route}
        variant="body2"
        color="secondary"
        sx={{ mr: 0.5 }}
      >
        <Tooltip title={tooltip} placement="top">
          <Icon>{icon}</Icon>
        </Tooltip>
      </SoftTypography>
      <SoftTypography key={`${icon}-text`} variant="button" color="text" fontWeight="regular" sx={{ mr: 2 }}>
        {icon[0].toUpperCase() + icon.slice(1)}
      </SoftTypography>
    </>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
        <Box display="flex" alignItems="right">
          {buttonsActions}
        </Box>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the WorkingHoursCard
WorkingHoursCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.shape(
    PropTypes.arrayOf({
      route: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default WorkingHoursCard;
