const importAll = (context) => {
  const routeFiles = context.keys();
  return routeFiles.map((key) => context(key).default);
};

const routeContext = require.context(
  "../configurations/components",
  true,
  /\/routes\/.+\.js$/
);

let pagesRoutes = [];

importAll(routeContext).map((components) => {
  pagesRoutes = pagesRoutes.concat(components);
});

export default pagesRoutes;
