// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// NewUser page components
import FormField from "../FormField";

function BankInfo({ formData }) {
  const { values, errors, touched, setFieldValue } = formData;
  const { bank, clabe, cardNumber, accountNumber } = formData.formField;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="bold" color="text">
          Información bancaria
        </SoftTypography>
      </SoftBox>
      <SoftBox my={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={bank.type}
              label={bank.label}
              name={bank.name}
              value={values.bank}
              placeholder={bank.placeholder}
              error={errors.bank && touched.bank}
              // success={values.bank.length > 0 && !errors.bank}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={clabe.type}
              label={clabe.label}
              name={clabe.name}
              value={values.clabe}
              placeholder={clabe.placeholder}
              error={errors.clabe && touched.clabe}
              // success={values.clabe.length > 0 && !errors.clabe}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={cardNumber.type}
              label={cardNumber.label}
              name={cardNumber.name}
              value={values.cardNumber}
              placeholder={cardNumber.placeholder}
              error={errors.cardNumber && touched.cardNumber}
              // success={values.cardNumber.length > 0 && !errors.cardNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={accountNumber.type}
              label={accountNumber.label}
              name={accountNumber.name}
              value={values.accountNumber}
              placeholder={accountNumber.placeholder}
              error={errors.accountNumber && touched.accountNumber}
              // success={values.accountNumber.length > 0 && !errors.accountNumber}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for BankInfo
BankInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BankInfo;
