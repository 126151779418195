// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Divider, Card } from "@mui/material/";

import Icon from "@mdi/react";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";


function IconCard({ color, icon, title, description, value, ...rest }) {
  return (
    <Card {...rest}>
      <SoftBox mt={7} mx={3} display="flex" justifyContent="center">
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="3rem"
          height="3rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon path={icon} size={1.3} color="white" />
        </SoftBox>
      </SoftBox>
      <SoftBox pt={1} textAlign="center" lineHeight={1.25}>
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography variant="caption" color="dark" fontWeight="mediun">
            {description}
          </SoftTypography>
        )}
        {description && !value ? null : (
          <SoftBox my={-1.2}>
            <Divider />
          </SoftBox>
        )}
        {value && (
          <SoftTypography variant="h6" fontWeight="mediun">
            {value}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of IconCard
IconCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the IconCard
IconCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconCard;
