const labels = {
  accountName: {
    type: "text",
    label: "Nombre de la cuenta",
  },
  activeFrom: {
    type: "date",
    label: "Activo desde",
  },
  nextRenewal: {
    type: "date",
    label: "Próxima renovación",
  },
  billing: {
    type: "text",
    label: "Facturación",
  },
  assets: {
    type: "text",
    label: "Activos",
  },
  agents: {
    type: "text",
    label: "Agentes",
  },
  dataCenter: {
    type: "text",
    label: "Centro de datos",
  },
  version: {
    type: "text",
    label: "Versión",
  },
};

export default labels;
