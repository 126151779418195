import { useState } from "react";

// @mui material components
import { Grid } from "@mui/material";

import ControllerCard from "./ControllerCard";

function Tasks() {
  const [cliente, setCliente] = useState(true);
  const [agente, setAgente] = useState(true);
  const [reprogramada, setReprogramada] = useState(true);
  const [cerrada, setCerrada] = useState(true);
  const [recordatorio, setRecordatorio] = useState(true);
  const [grupo, setGrupo] = useState(true);

  return (
    <Grid container spacing={8} columns={16} px={10} py={5}>
      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={cliente}
          title="Tarea asignada a cliente"
          onChange={() => setCliente(!cliente)}
        />
      </Grid>

      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={agente}
          title="Tarea asignada a agente"
          onChange={() => setAgente(!agente)}
        />
      </Grid>

      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={reprogramada}
          title="Tarea reprogramada"
          onChange={() => setReprogramada(!reprogramada)}
        />
      </Grid>

      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={cerrada}
          title="Tarea cerrada"
          onChange={() => setCerrada(!cerrada)}
        />
      </Grid>

      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={recordatorio}
          title="Recordatorio de la Tarea"
          onChange={() => setRecordatorio(!recordatorio)}
        />
      </Grid>

      <Grid item xs={8} sm={4} lg={4}>
        <ControllerCard
          state={grupo}
          title="Tarea asignada al grupo"
          onChange={() => setGrupo(!grupo)}
        />
      </Grid>
    </Grid>
  );
}

export default Tasks;
