import { createApi } from "@reduxjs/toolkit/query/react";
import { RTKBaseQuery } from "./helper";

const GENERIC_LIST = (module, subModule, resource) =>
  `v1/${module}/${subModule}/${resource}`;

export const generalsApi = createApi({
  reducerPath: "generalsApi",

  baseQuery: RTKBaseQuery,

  endpoints: (builder) => ({
    getPerson: builder.query({
      query: () => `${GENERIC_LIST("global", "test", "index")}`,
    }),
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
});

export const { useGetPersonQuery } = generalsApi;
