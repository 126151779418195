import { useState, useEffect } from "react";
import i18n from "i18next";
// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Icon,
  Grid,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";
import SoftInput from "softMui/SoftInput";
import SoftAvatar from "softMui/SoftAvatar";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser } from "redux/slices/authUser";
import { setCurrentLang } from "redux/slices/ui";
import { SignOutKeyCloak } from "App/Modules/auth/sign-in/keycloak/logout";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
  alignGrid,
} from "components/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import cereza from "assets/images/cereza.png";
import cerezaPink2 from "assets/images/CEREZA_SOFT_LOGOTIPO_transparencia.png";
import TranslateIcon from "@mui/icons-material/Translate";

import { useTranslation } from "react-i18next";
import Dictionary from "translate.route/Dictionary";
import { navbarDesktopMenu } from "./styles";
function DashboardNavbar({ absolute, light, isMini }) {
  const { t } = useTranslation(["header"]);
  const dispatcher = useDispatch();
  const lang = useSelector((state) => state.ui.language);
  const languageOptions = [
    { name: "English", abb: "en" },
    { name: "Español", abb: "es" },
    { name: "Français", abb: "fr" },
    { name: "Português", abb: "pt" },
  ];

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
  } = controller;
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);

  const handleLogOut = () => {
    SignOutKeyCloak();
    //TODO : Chequear si  es necesario o no
    // dispatcher(setAuthUser(null));
  };
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          color="inherit"
          display="flex"
          boder="solid"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <SoftBox pr={3}>
            <Icon
              fontSize="medium"
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              mb="2"
              border="solid"
            >
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </SoftBox>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox pr={1}>
              <SoftInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox>
            <SoftBox color={light ? "white" : "inherit"}>
              <IconButton
                sx={navbarIconButton}
                size="small"
                onClick={() => {
                  SignOutKeyCloak();
                }}
              >
                <LogoutIcon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                />
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  {t("header:logout")}
                </SoftTypography>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                size="small"
                sx={({ palette: { dark, white } }) => ({
                  ...navbarIconButton,
                  color: light ? white.main : dark.main,
                })}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <TranslateIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                PaperProps={{
                  style: {
                    maxHeight: 33 * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {languageOptions.map((option) => (
                  <MenuItem
                    key={option.abb}
                    selected={option.abb === lang.abb}
                    onClick={() => {
                      setAnchorEl(null);
                      i18n.changeLanguage(option.abb);
                      dispatcher(
                        setCurrentLang({
                          abb: option.abb,
                          name: option.name,
                        })
                      );
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  notifications
                </Icon>
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
