// date-fns methods
import { parse, format } from "date-fns";
import esLocale from "date-fns/locale/es";

function FormatDate(date, formatType = "default") {
  switch (formatType) {
    case "default":
      const fechaObjeto = parse(date, "dd/MM/yyyy", new Date());
      return format(fechaObjeto, "dd MMMM, yyyy", { locale: esLocale });
    default:
      return date;
  }
}

export default FormatDate;
