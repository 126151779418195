import { Switch, Grid } from "@mui/material";
import borders from "assets/theme/base/borders";
import FormField from "App/Modules/employee/components/FormField";
import {
  SoftBox,
  SoftButton,
  SoftTypography,
  SoftTagInput,
  SoftSelect,
  SoftDatePicker,
} from "softMui";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import DeleteIcon from "@mui/icons-material/Delete";
import { FieldArray } from "formik";
import { useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";

function renderFieldArray(fieldArray, formik, nameFileArray) {
  const { uiState } = useSelector((state) => state.ui);
  const [myArray, setMyArray] = useState(fieldArray);
  const { borderWidth, borderColor } = borders;

  return (
    <>
      <FieldArray
        name={nameFileArray}
        render={(arrayHelpers, index) => (
          <Grid key={index} item xs={12} sm={12} mb={2}>
            <SoftBox
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
              }}
            >
              <SoftBox>
                <SoftButton
                  style={uiState === "viewing" ? { display: "none" } : {}}
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() => {
                    myArray.push(fieldArray[0]);
                    arrayHelpers.push(formik.values[nameFileArray][0]);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
            {myArray?.map((value, index) => (
              <SoftBox
                mt={1}
                padding={3}
                borderRadius="md"
                border={`${borderWidth[2]} solid ${borderColor}`}
                key={index}
              >
                <Grid container spacing={1} key={index}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      sx={{
                        display: "grid",
                        justifyContent: "end",
                        alignItems: "end",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <SoftButton
                        style={uiState === "viewing" ? { display: "none" } : {}}
                        variant="gradient"
                        color="warning"
                        iconOnly
                        onClick={() => {
                          console.log(myArray);
                          myArray.splice(index, 1);
                          arrayHelpers.remove(index);
                        }}
                      >
                        <DeleteIcon />
                      </SoftButton>
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      {renderFields(value, formik, nameFileArray, index)}
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            ))}
          </Grid>
        )}
      />
    </>
  );
}
export const renderFields = (
  fields,
  formik,
  nameFileArray = "",
  index = null
) => {
  if (Array.isArray(fields)) {
    return renderFieldArray(fields, formik, nameFileArray);
  }
  return Object.values(fields).map((field) => {
    if (field?.type === "text") {
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    } else if (field?.type === "date") {
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    } else if (field?.type === "email") {
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    } else if (field?.type === "select")
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    else if (field?.type === "number")
      return renderField(field, formik, field.size ?? 3, nameFileArray, index);
    else if (field?.type === "labels")
      return renderField(field, formik, field.size ?? 12, nameFileArray, index);
    else if (field?.type === "switch")
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    else if (field?.type === "checkbox")
      return renderField(field, formik, field.size ?? 6, nameFileArray, index);
    else if (field?.type === "tag")
      return renderField(field, formik, field.size ?? 12, nameFileArray, index);
    else if (Array.isArray(field))
      return renderFieldArray(field, formik, nameFileArray, index);
    else return null;
  });
};

export const renderField = (
  field,
  formik,
  size = 6,
  nameFileArray = "",
  index = null
) => {
  const renderLabel = () => {
    return (
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography component="label" variant="caption">
          {field.label}
        </SoftTypography>
      </SoftBox>
    );
  };

  const renderTextField = () => {
    const {
      name,
      type,
      label,
      placeholder,
      isRequired,
      maxLength,
      multiline,
      rows,
      readOnly,
    } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <FormField
        type={type}
        label={label}
        name={name}
        value={defaultValue}
        onChange={
          index !== null
            ? (event) => {
                formik.setFieldValue(
                  `${nameFileArray}.${index}.${name}`,
                  event.target.value
                );
              }
            : formik.handleChange
        }
        placeholder={placeholder}
        isRequired={isRequired ?? false}
        error={formik.errors[name] && formik.touched[name]}
        inputProps={{ maxLength }}
        multiline={multiline ?? false}
        rows={rows ?? 1}
        readOnly={readOnly ?? false}
        disabled={readOnly ?? false}
      />
    );
  };

  const renderEmailField = () => {
    const {
      name,
      type,
      label,
      placeholder,
      isRequired,
      maxLength,
      multiline,
      rows,
      readOnly,
    } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <FormField
        type={type}
        label={label}
        name={name}
        value={defaultValue}
        onChange={
          index !== null
            ? (event) => {
                formik.setFieldValue(
                  `${nameFileArray}.${index}.${name}`,
                  event.target.value
                );
              }
            : formik.handleChange
        }
        placeholder={placeholder}
        isRequired={isRequired ?? false}
        error={formik.errors[name] && formik.touched[name]}
        inputProps={{ maxLength }}
        multiline={multiline ?? false}
        rows={rows ?? 1}
        readOnly={readOnly ?? false}
        disabled={readOnly ?? false}
      />
    );
  };

  const renderDatePicker = () => {
    const { name, placeholder } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <>
        {renderLabel()}
        <SoftDatePicker
          name={name}
          options={{ dateFormat: "Y/m/d" }}
          placeholder={placeholder}
          value={defaultValue}
          onChange={
            index !== null
              ? (newDate) => {
                  formik.setFieldValue(
                    `${nameFileArray}.${index}.${name}`,
                    format(newDate[0], "yyyy-MM-dd")
                  );
                }
              : (newDate) => {
                  formik.setFieldValue(name, format(newDate[0], "yyyy-MM-dd"));
                }
          }
          error={formik.errors[name] ? true : false}
        />
      </>
    );
  };

  const renderSelectField = () => {
    const { name, options, isMulti } = field;

    let defaultValue = {};
    if (nameFileArray === "") {
      if (formik.values[name] === "") {
        defaultValue = {
          value: "",
          label: "Select...",
        };
      } else {
        defaultValue = {
          value: formik.values[name],
          label: formik.values[name],
        };
      }
    } else {
      defaultValue = {
        value: formik.values[nameFileArray][index][name],
        label: formik.values[nameFileArray][index][name],
      };
    }

    return (
      <>
        {renderLabel()}
        <SoftSelect
          name={name}
          value={defaultValue}
          options={options}
          isMulti={isMulti ?? false}
          onChange={(event) => {
            if (isMulti) {
              const result = event.map((item) => item.value);
              index !== null
                ? formik.setFieldValue(
                    `${nameFileArray}.${index}.${name}`,
                    event.value
                  )
                : formik.setFieldValue(name, result);
            } else {
              index !== null
                ? formik.setFieldValue(
                    `${nameFileArray}.${index}.${name}`,
                    event.value
                  )
                : formik.setFieldValue(name, event.value);
            }
          }}
          error={formik.errors[name] && formik.touched[name]}
        />
      </>
    );
  };

  const renderTagInput = () => {
    const { name, label } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        {renderLabel()}
        <SoftTagInput
          tags={defaultValue}
          placeholder=" "
          onChange={
            index !== null
              ? (newTag) => {
                  formik.setFieldValue(
                    `${nameFileArray}.${index}.${name}`,
                    newTag
                  );
                }
              : (newTag) => formik.setFieldValue(name, newTag)
          }
          removeOnBackspace
        />
      </SoftBox>
    );
  };

  const renderSwitch = () => {
    const { name } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <SoftBox display="block" py={1} mb={0.25}>
        {renderLabel()}
        <SoftBox>
          <Switch
            checked={defaultValue}
            onChange={
              index !== null
                ? () => {
                    formik.setFieldValue(
                      `${nameFileArray}.${index}.${name}`,
                      !formik.values[name]
                    );
                  }
                : () => formik.setFieldValue(name, !formik.values[name])
            }
          />
        </SoftBox>
      </SoftBox>
    );
  };

  const renderCheckBox = () => {
    const { name } = field;

    let defaultValue = "";
    if (nameFileArray === "") {
      defaultValue = formik.values[name];
    } else {
      defaultValue = formik.values[nameFileArray][index][name];
    }

    return (
      <SoftBox
        display="grid"
        style={{
          gap: "10px",
          gridTemplateColumns: "1fr auto",
          height: "100%",
          alignItems: "end",
        }}
      >
        {renderLabel()}
        <SoftBox mb={0.5}>
          <Checkbox
            checked={defaultValue}
            onChange={
              index !== null
                ? () => {
                    formik.setFieldValue(
                      `${nameFileArray}.${index}.${name}`,
                      !formik.values[name]
                    );
                  }
                : () => formik.setFieldValue(name, !formik.values[name])
            }
          />
        </SoftBox>
      </SoftBox>
    );
  };

  switch (field.type) {
    case "text":
    case "number":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderTextField()}
        </Grid>
      );
    case "email":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderEmailField()}
        </Grid>
      );
    case "date":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderDatePicker()}
        </Grid>
      );
    case "select":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderSelectField()}
        </Grid>
      );
    case "tag":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderTagInput()}
        </Grid>
      );
    case "switch":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderSwitch()}
        </Grid>
      );
    case "checkbox":
      return (
        <Grid key={field.name} item xs={12} sm={size}>
          {renderCheckBox()}
        </Grid>
      );
    default:
      return null;
  }
};
