// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";
import SoftButton from "softMui/SoftButton";

// Billing page components
import Invoice from "../Invoice";

// Data
import invoicesData from "./data";

// Utils functions
import FormatDate from "utils/DateFormat";

function Invoices() {
    const renderData = invoicesData.map(({date, id, price}) => (
        <Invoice key={id} date={FormatDate(date)} id={id} price={price} />
      ));

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SoftTypography variant="button" display="block" fontWeight="bold">
          Facturas
        </SoftTypography>
        <SoftButton variant="outlined" color="primary" size="small">
          ver todas
        </SoftButton>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {renderData}
          {/* <Invoice date="March, 01, 2019" id="#AR-803481" price="$300" noGutter /> */}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Invoices;
