// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// NewUser page components
import FormField from "../FormField";

import SoftSelect from "softMui/SoftSelect";
import SoftDatePicker from "softMui/SoftDatePicker";

function EmployeeInfo({ formData }) {
  const { values, errors, touched, setFieldValue } = formData;
  const {
    entryDate,
    deparment,
    position,
    reportingPerson,
    contractType,
    status,
    location,
    checkoutDate,
  } = formData.formField;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="bold" color="text">
          Información de empleado
        </SoftTypography>
      </SoftBox>
      <SoftBox my={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {deparment.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={deparment.name}
                    placeholder={deparment.placeholder}
                    defaultValue={values.deparment ?? null}
                    options={[
                      { value: "RH", label: "Recursos humanos" },
                      { value: "DEV", label: "Desarrollo" },
                      { value: "CONT", label: "Contabilidad" },
                      { value: "AD", label: "Administrativo" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {position.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={position.name}
                    placeholder={position.placeholder}
                    defaultValue={values.position ?? null}
                    options={[
                      { value: "G", label: "Gerente" },
                      { value: "SG", label: "Subgerente" },
                    ]}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {entryDate.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker
                    name={entryDate.name}
                    placeholder={entryDate.placeholder}
                    value={values.entryDate}
                    onChange={(newDate) =>
                      setFieldValue(`${entryDate.name}`, newDate)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {checkoutDate.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker
                    name={checkoutDate.name}
                    placeholder={checkoutDate.placeholder}
                    value={values.checkoutDate}
                    onChange={(newDate) =>
                      setFieldValue(`${checkoutDate.name}`, newDate)
                    }
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {contractType.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={contractType.name}
                    placeholder={contractType.placeholder}
                    defaultValue={values.contractType ?? null}
                    options={[
                      { value: "TF", label: "Fijo" },
                      { value: "TI", label: "Indefinido" },
                      { value: "C", label: "Contratista" },
                      { value: "P", label: "Pasantía" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {status.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={status.name}
                    placeholder={status.placeholder}
                    defaultValue={values.status ?? null}
                    options={[
                      { value: "A", label: "Activo" },
                      { value: "I", label: "Inactivo" },
                      { value: "V", label: "Vacaciones" },
                    ]}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={location.type}
              label={location.label}
              name={location.name}
              value={values.location}
              placeholder={location.placeholder}
              error={errors.location && touched.location}
              // success={values.location.length > 0 && !errors.location}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={reportingPerson.type}
              label={reportingPerson.label}
              name={reportingPerson.name}
              value={values.reportingPerson}
              placeholder={reportingPerson.placeholder}
              error={errors.reportingPerson && touched.reportingPerson}
              // success={values.reportingPerson.length > 0 && !errors.reportingPerson}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for EmployeeInfo
EmployeeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EmployeeInfo;
