// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";

// Soft UI Dashboard React components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// WorkingHours page components
import Header from "../components/Header";

// Billing page components
import Invoices from "./components/Invoices";
import BillingInformation from "./components/BillingInformation";
import Transactions from "./components/Transactions";
import CurrentPlan from "../components/CurrentPlan";
import PaymentInformation from "./components/PaymentInformation";
import Pricing from "./pricing";

function PlansAndBilling() {
  return (
    <DashboardLayout>
      <Header />

      <SoftBox my={3}>
        <CurrentPlan />
      </SoftBox>

      <SoftBox mt={4}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PaymentInformation />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Invoices />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation />
            </Grid>
            <Grid item xs={12} md={5}>
              <Transactions />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox
          px={12}
          py={5}
          borderRadius="xl"
          bgColor="white"
          position="relative"
          overflow="hidden"
        >
          <Pricing />
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default PlansAndBilling;
