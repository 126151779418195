const form = {
  formId: "form-94",
  formField: {
    edition: {
      name: "edition",
      label: "Edición",
      type: "string",
      placeholder: "eg. v1.0.0",
      errorMsg: "Edition is required.",
      invalidMsg: "Edition is invalid",
      isRequired: true,
      maxLength: 500,
      group: "edition",
    },
    monthlyPrice: {
      name: "monthlyPrice",
      label: "Precio mensual",
      type: "number",
      decimals: 2,
      placeholder: "eg. 100",
      errorMsg: "Monthly price is required.",
      invalidMsg: "Monthly price is invalid",
      isRequired: true,
      group: "edition",
    },
    annualPrice: {
      name: "annualPrice",
      label: "Precio anual",
      type: "number",
      decimals: 2,
      placeholder: "eg. 100",
      errorMsg: "Annual price is required.",
      invalidMsg: "Annual price is invalid",
      isRequired: true,
      group: "edition",
    },
    employeeLimit: {
      name: "employeeLimit",
      label: "Límite de empleados",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Employee limit is required.",
      invalidMsg: "Employee limit is invalid",
      isRequired: true,
      group: "features",
    },
    emailLimit: {
      name: "emailLimit",
      label: "Límite de correos",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Email limit is required.",
      invalidMsg: "Email limit is invalid",
      isRequired: true,
      group: "features",
    },
    spaceLimit: {
      name: "spaceLimit",
      label: "Límite de espacio",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Space limit is required.",
      invalidMsg: "Space limit is invalid",
      isRequired: true,
      group: "features",
    },
    adminLimit: {
      name: "adminLimit",
      label: "Límite de administradores",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Admin limit is required.",
      invalidMsg: "Admin limit is invalid",
      isRequired: true,
      group: "features",
    },
    areasLimit: {
      name: "areasLimit",
      label: "Límite de áreas",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Areas limit is required.",
      invalidMsg: "Areas limit is invalid",
      isRequired: true,
      group: "features",
    },
    templatesLimit: {
      name: "templatesLimit",
      label: "Límite de plantillas",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Templates limit is required.",
      invalidMsg: "Templates limit is invalid",
      isRequired: true,
      group: "features",
    },
    vacanciesLimit: {
      name: "vacanciesLimit",
      label: "Límite de vacantes",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Vacancies limit is required.",
      invalidMsg: "Vacancies limit is invalid",
      isRequired: true,
      group: "features",
    },
    currentsLimit: {
      name: "currentsLimit",
      label: "Límite de corrientes",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Currents limit is required.",
      invalidMsg: "Currents limit is invalid",
      isRequired: true,
      group: "features",
    },
    interviewsLimit: {
      name: "interviewsLimit",
      label: "Límite de entrevistas",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Interviews limit is required.",
      invalidMsg: "Interviews limit is invalid",
      isRequired: true,
      group: "features",
    },
    evaluationsLimit: {
      name: "evaluationsLimit",
      label: "Límite de evaluaciones",
      type: "number",
      placeholder: "eg. 100",
      errorMsg: "Evaluations limit is required.",
      invalidMsg: "Evaluations limit is invalid",
      isRequired: true,
      group: "features",
    },
    emailHelp: {
      name: "emailHelp",
      label: "Ayuda por email",
      type: "boolean",
      errorMsg: "Email help is required.",
      invalidMsg: "Email help is invalid",
      isRequired: true,
      group: "supportAndConnection",
    },
    chatHelp: {
      name: "chatHelp",
      label: "Ayuda por chat",
      type: "boolean",
      errorMsg: "Chat help is required.",
      invalidMsg: "Chat help is invalid",
      isRequired: true,
      group: "supportAndConnection",
    },
    phoneHelp: {
      name: "phoneHelp",
      label: "Ayuda por teléfono",
      type: "boolean",
      errorMsg: "Phone help is required.",
      invalidMsg: "Phone help is invalid",
      isRequired: true,
      group: "supportAndConnection",
    },
    smtpFeature: {
      name: "smtpFeature",
      label: "Funcionalidad SMTP",
      type: "boolean",
      errorMsg: "SMTP feature is required.",
      invalidMsg: "SMTP feature is invalid",
      isRequired: true,
      group: "supportAndConnection",
    },
    slaHelp: {
      name: "slaHelp",
      label: "Ayuda SLA",
      type: "text",
      placeholder: "eg. placeholder de ayuda",
      errorMsg: "SLA help is required.",
      invalidMsg: "SLA help is invalid",
      isRequired: true,
      maxLength: 500,
      group: "supportAndConnection",
    },
  },
};

export default form;
