const currentPlanData = {
  accountName: "DeSiCi",
  activeFrom: "14/04/2023",
  nextRenewal: "14/04/2023",
  billing: "Mensual",
  assets: "10 + Gratis 5",
  agents: "18",
  dataCenter: "Estados Unidos de América",
  version: "2023.R02.16",
};

export default currentPlanData;
