import i18n from "i18next";
import Backend from "i18next-http-backend";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "es", "fr", "pt"],
    backend: {
      loadPath: "/translate/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "es",
    // disabled in production
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },

    ns: ["common", "home", "profile", "header", "accountHeader", "pageRoutes"],

    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
