import checkout from "./form";

const {
  formField: {
    // Personal information
    names,
    surnames,
    gender,
    dateOfBirth,
    civilStatus,
    socialSecurityNumber,
    curp,
    rfc,
    cellphone,
    phone,
    email,
    // employee information
    entryDate,
    deparment,
    position,
    reportingPerson,
    contractType,
    status,
    location,
    checkoutDate,

    // experience
    backCompany,
    backPosition,
    birthday,
    description,
    relevant,

    // bank information
    bank,
    clabe,
    cardNumber,
    accountNumber,

    // emergency contact
    emergencyContactNames,
    emergencyContactSurnames,
    emergencyContactRelationship,
    emergencyContactPhone,
  },
} = checkout;

const initialValues = {
  // Personal information
  [names.name]: "",
  [surnames.name]: "",
  [gender.name]: "",
  [dateOfBirth.name]: "",
  [civilStatus.name]: "",
  [socialSecurityNumber.name]: "",
  [curp.name]: "",
  [rfc.name]: "",
  [cellphone.name]: "",
  [phone.name]: "",
  [email.name]: "",

  // employee information
  [entryDate.name]: "",
  [deparment.name]: "",
  [position.name]: "",
  [reportingPerson.name]: "",
  [contractType.name]: "",
  [status.name]: "",
  [location.name]: "",
  [checkoutDate.name]: "",

  // experience
  [backCompany.name]: "",
  [backPosition.name]: "",
  [birthday.name]: "",
  [description.name]: "",
  [relevant.name]: "",

  // bank information
  [bank.name]: "",
  [clabe.name]: "",
  [cardNumber.name]: "",
  [accountNumber.name]: "",

  // emergency contact
  [emergencyContactNames.name]: "",
  [emergencyContactSurnames.name]: "",
  [emergencyContactRelationship.name]: "",
  [emergencyContactPhone.name]: "",
};

export default initialValues;
