const form = {
  formId: "personal-information-form",
  formField: {
    // Personal information
    names: {
      name: "names",
      label: "Nombre (s)",
      type: "text",
      placeholder: "eg. Micheal",
      errorMsg: "First name is required.",
    },
    surnames: {
      name: "surnames",
      label: "Apellidos",
      type: "text",
      placeholder: "eg. Prior Pool",
      errorMsg: "Last name is required.",
    },
    gender: {
      name: "gender",
      label: "Género",
      type: "text",
      placeholder: "Female",
    },
    dateOfBirth: {
      name: "date-of-birth",
      label: "Fecha de nacimiento",
      type: "text",
      placeholder: "23/01/1998",
      errorMsg: "Date of birth is required.",
      invalidMsg: "Date of birth is invalid",
    },
    civilStatus: {
      name: "civil-status",
      label: "Estado civil",
      type: "text",
      placeholder: "Soltero",
      errorMsg: "Civil status is required.",
    },
    socialSecurityNumber: {
      name: "social-security-number",
      label: "Número de seguridad social",
      type: "text",
      placeholder: "23456789",
      errorMsg: "Social security number is required.",
    },
    curp: {
      name: "curp",
      label: "CURP",
      type: "text",
      placeholder: "eg. JLL9876YH879054J",
      errorMsg: "CURP is required.",
    },
    rfc: {
      name: "rfc",
      label: "RFC",
      type: "text",
      placeholder: "eg. JLL9876YH879054J848757",
    },
    cellphone: {
      name: "cellphone",
      label: "Teléfono celular",
      type: "text",
      placeholder: "eg. 55 657 890 45",
      errorMsg: "City is required.",
    },
    phone: {
      name: "phone",
      label: "Teléfono particular",
      type: "text",
      placeholder: "eg. 55 657 890 45",
      errorMsg: "City is required.",
    },
    email: {
      name: "email",
      label: "Correo electrónico",
      type: "email",
      placeholder: "eg. DeSiCi@cereza.com",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },

    // employee information
    entryDate: {
      name: "entryDate",
      label: "Fecha de ingreso",
      type: "date",
      placeholder: "23/01/1998",
      errorMsg: "Date of entry is required.",
    },
    deparment: {
      name: "deparment",
      label: "Departamento",
      type: "text",
      placeholder: "Recursos humanos",
    },
    position: {
      name: "position",
      label: "Puesto",
      type: "text",
      placeholder: "Subgerente",
    },
    reportingPerson: {
      name: "reportingPerson",
      label: "Persona a quien reporta",
      type: "text",
      placeholder: "Juan Hernández López",
    },
    contractType: {
      name: "contractType",
      label: "Tipo de contrato",
      type: "text",
      placeholder: "Temporal",
    },
    status: {
      name: "status",
      label: "Estado del empleado",
      type: "text",
      placeholder: "Activo",
    },
    location: {
      name: "location",
      label: "Ubicación",
      type: "text",
      placeholder: "Ubicación del empleado",
    },
    checkoutDate: {
      name: "checkoutDate",
      label: "Fecha de salida",
      type: "text",
      placeholder: "---",
    },

    // Experience
    backCompany: {
      name: "backCompany",
      label: "Nombre de la empresa anterior",
      type: "text",
      placeholder: "eg. Cereza",
    },
    backPosition: {
      name: "backPosition",
      label: "Cargo",
      type: "text",
      placeholder: "eg. Subgerente",
    },
    birthday: {
      name: "birthday",
      label: "Fecha de nacimiento",
      type: "text",
      placeholder: "eg. 23/01/1998",
    },
    description: {
      name: "description",
      label: "Descripción del empleado",
      type: "text",
      placeholder: "eg. El empleado se destaca por su...",
    },
    relevant: {
      name: "relevant",
      label: "Relevante",
      type: "text",
      placeholder: "eg. Estudiante de la carrera de...",
    },

    // Bank information
    bank: {
      name: "bank",
      label: "Banco",
      type: "text",
      placeholder: "eg. Banamex",
    },
    clabe: {
      name: "clabe",
      label: "Cuenta clabe",
      type: "text",
      placeholder: "eg. 123456789",
    },
    cardNumber: {
      name: "cardNumber",
      label: "Número de tarjeta",
      type: "text",
      placeholder: "eg. 123456789",
    },
    accountNumber: {
      name: "accountNumber",
      label: "Número de cuenta",
      type: "text",
      placeholder: "eg. 123456789",
    },

    // Emergency contact
    emergencyContactNames: {
      name: "emergencyContactNames",
      label: "Nombre (s)",
      type: "text",
      placeholder: "eg. Juan",
    },
    emergencyContactSurnames: {
      name: "emergencyContactSurnames",
      label: "Apellidos",
      type: "text",
      placeholder: "eg. Hernández López",
    },
    emergencyContactRelationship: {
      name: "emergencyContactRelationship",
      label: "Parentesco",
      type: "text",
      placeholder: "eg. Padre",
    },
    emergencyContactPhone: {
      name: "emergencyContactPhone",
      label: "Teléfono",
      type: "text",
      placeholder: "eg. 55 657 890 45",
    },
  },
};

export default form;
