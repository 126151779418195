import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    taskName,
    startAt,
    endAt,
    reminder,
    assigned,
    status,
    description,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [taskName.name]: Yup.string().required(taskName.errorMsg),
    [startAt.name]: Yup.date().required(startAt.errorMsg),
    [endAt.name]: Yup.date().required(endAt.errorMsg),
    [reminder.name]: Yup.boolean().required(),
    [assigned.name]: Yup.string().required(assigned.errorMsg),
    [status.name]: Yup.string().required(status.errorMsg).oneOf(status.options),
    [description.name]: Yup.string().required(description.errorMsg),
  }),
];

export default validations;
