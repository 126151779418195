// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Soft UI Dashboard PRO React components
import SoftInput from "softMui/SoftInput";

function SoftDatePicker({ input, error, ...rest }) {
  const { placeholder } = rest;
  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <SoftInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          placeholder={placeholder}
          error={error}
        />
      )}
    />
  );
}

// Setting default values for the props of SoftDatePicker
SoftDatePicker.defaultProps = {
  input: {},
  error: false,
};

// Typechecking props for the SoftDatePicker
SoftDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

export default SoftDatePicker;
