const form = {
  formId: "form-88",
  formField: {
    taskName: {
      name: "taskName",
      label: "Nombre de la tarea",
      type: "text",
      placeholder: "eg. Crear modelo de datos",
      maxLength: 1024,
    },
    startAt: {
      name: "startAt",
      label: "Fecha de inicio",
      type: "date",
      placeholder: "eg. 23/01/1998",
      errorMsg: "Start at is required.",
      invalidMsg: "Start at is invalid",
      isRequired: true,
    },
    endAt: {
      name: "endAt",
      label: "Fecha de finalización",
      type: "date",
      placeholder: "eg. 23/01/1998",
      errorMsg: "End at is required.",
      invalidMsg: "End at is invalid",
      isRequired: true,
    },
    reminder: {
      name: "reminder",
      label: "Recordatorio",
      type: "boolean",
      value: false,
    },
    assigned: {
      name: "assigned",
      label: "Asignado a",
      type: "string",
      placeholder: "eg. Juan Perez",
      maxLength: 500,
    },
    status: {
      name: "status",
      label: "Modalidad",
      type: "select",
      options: [
        { value: "pendiente", label: "Pendiente" },
        { value: "enProgreso", label: "En progreso" },
        { value: "completado", label: "Completado" },
      ],
      errorMsg: "Status is required.",
      isRequired: true,
      size: 6,
    },
    description: {
      name: "description",
      label: "Descripción del puesto",
      type: "text",
      errorMsg: "",
      maxLength: 5000,
      size: 12,
      multiline: true,
      rows: 5,
    },
  },
};

export default form;
