// @mui material components
import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftButton from "softMui/SoftButton";
import SoftTypography from "softMui/SoftTypography";
import IconCard from "./components/IconCard";
import MasterCard from "components/Cards/MasterCard";

import { mdiWalletBifold, mdiCurrencyUsd } from "@mdi/js";

function PaymentInformation() {
  return (
    <Card id="payment-information" sx={{ bgcolor: "white" }}>
      <SoftBox pt={3} px={2} pb={4}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            <SoftTypography variant="button" display="block" fontWeight="bold">
              Información de pago
            </SoftTypography>
          </Grid>
          <Grid item>
            <SoftButton variant="text" color="info" size="small">
              <Icon>edit</Icon>&nbsp;editar
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6}>
            <MasterCard
              number={4562112245947852}
              holder="DeSiCi"
              expires="11/22"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <IconCard
              icon={mdiWalletBifold}
              description="Crédito disponible"
              value="+$2000"
              sx={{ boxShadow: "none" }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <IconCard
              icon={mdiCurrencyUsd}
              description="Total"
              value="$400"
              sx={{ boxShadow: "none" }}
            />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox pt={3} pb={5} px={2}>
        <SoftButton variant="outlined" color="primary" size="small">
          + agregar una nueva tarjeta
        </SoftButton>
      </SoftBox>
    </Card>
  );
}

export default PaymentInformation;
