import { useMemo } from "react";

import Header from "../../../Header";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftButton from "softMui/SoftButton";
import SoftTypography from "softMui/SoftTypography";

// Material-UI components
import { Card, Grid } from "@mui/material";

// Formik
import { Form, Formik } from "formik";

// import formData from "./schemas/form";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

// Render form fields
import Edition from "./components/Edition";
import Features from "./components/Features";
import SupportAndConnection from "./components/SupportAndConnection";

const filterFieldsByGroup = (fields, group) => {
  return Object.values(fields).filter((field) => field.group === group);
};

function Editions() {
  const submitForm = async (values, actions) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const editionFields = useMemo(() =>
    filterFieldsByGroup(form.formField, "edition")
  );
  const featureFields = useMemo(() =>
    filterFieldsByGroup(form.formField, "features")
  );
  const supportConnFields = useMemo(() =>
    filterFieldsByGroup(form.formField, "supportAndConnection")
  );

  return (
    <DashboardLayout>
      <Header />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              //   validationSchema={validations}
              onSubmit={submitForm}
            >
              {(formik) => (
                <>
                  <Form id={form.formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <SoftBox p={2}>
                        <SoftBox lineHeight={0} mb={3}>
                          <SoftTypography variant="h5" fontWeight="bold">
                            Administración
                          </SoftTypography>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            ABC de Ediciones y/o Permisos
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Edition formik={formik} fields={editionFields} />
                          <Features formik={formik} fields={featureFields} />
                          <SupportAndConnection
                            formik={formik}
                            fields={supportConnFields}
                          />
                        </SoftBox>
                        <SoftBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <SoftButton
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            Enviar
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Editions;
