const horariosLaboralesData = [
  {
    title: "Soporte Matutino",
    description: "De 6 A.M. a 3 P.M.",
    actions: [
      { route: "", tooltip: "Eliminar Profile", icon: "delete" },
      { route: "", tooltip: "Edit Profile", icon: "edit" },
    ],
  },
  {
    title: "Soporte vespertino",
    description: "De 3 P.M. a 11 P.M.",
    actions: [
      { route: "", tooltip: "Eliminar Profile", icon: "delete" },
      { route: "", tooltip: "Edit Profile", icon: "edit" },
    ],
  },
  {
    title: "Default",
    description: "Default Business Calendar",
    actions: [{ route: "", tooltip: "Edit Profile", icon: "edit" }],
  },
];

export default horariosLaboralesData;

// {
//     name: "Soporte Matutino",
//     description: "De 6 A.M. a 3 P.M.",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "reply",
//     },
//   },
