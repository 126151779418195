// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material UI components
import { Card, CardContent, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

import { renderFields } from "utils/buildFields";

function Edition({ formik, fields }) {
  return (
    <SoftBox>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <CardContent>
          <SoftTypography variant="h5" pb={3} pt={1}>
            Ediciones
          </SoftTypography>
          <Grid container spacing={3}>
            {renderFields(fields, formik)}
          </Grid>
        </CardContent>
      </Card>
    </SoftBox>
  );
}

// Typechecking props for the Edition
Edition.propTypes = {
  formik: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

export default Edition;
