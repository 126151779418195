// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

function ControllerCard({ state, title, onChange }) {
  return (
    <SoftBox
      p={2}
      height="100%"
      bgColor="white"
      variant="gradient"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      shadow="md"
      borderRadius="lg"
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        lineHeight={1}
      >
        <SoftTypography variant="caption" display="block" color="text">
          {state ? "ACTIVA" : "INACTIVA"}
        </SoftTypography>
        <SoftBox>
          <Switch checked={state} onChange={onChange} />
        </SoftBox>
      </SoftBox>
      <SoftBox mt={1} lineHeight={1}>
        <SoftTypography
          variant="button"
          display="block"
          color="dark"
          fontWeight="medium"
        >
          {title}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of ControllerCard
ControllerCard.defaultProps = {
  color: "info",
  state: true,
};

// Typechecking props for the ControllerCard
ControllerCard.propTypes = {
  state: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ControllerCard;
