import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import Header from "../account/components/Header";

import SoftBox from "softMui/SoftBox";

import { Grid } from "@mui/material";

// Employee components
import HeaderProfile from "../account/components/HeaderProfile";
import PersonalInfo from "./components/PersonalInfo";
import formData from "./schemas/form";
import { Form, Formik } from "formik";
import initialValues from "./schemas/initialValues";
import EmployeeInfo from "./components/EmployeeInfo";
import Experience from "./components/Experience";
import BankInfo from "./components/BankInfo";
import Contacts from "./components/Contacts";

function bgBox(children) {
  return (
    <SoftBox
      mt={5}
      mb={3}
      bgColor="white"
      sx={{ px: 10, py: 3 }}
      borderRadius="xl"
    >
      {children}
    </SoftBox>
  );
}

function Employee() {
  const { formId, formField } = formData;

  const handleSubmit = (values, actions) => {
    console.log("values", values);
    console.log("actions", actions);
  };

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <HeaderProfile />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <Formik
              initialValues={initialValues}
              //   validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  {bgBox(
                    <PersonalInfo
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        formField,
                      }}
                    />
                  )}
                  {bgBox(
                    <EmployeeInfo
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        formField,
                      }}
                    />
                  )}
                  {bgBox(
                    <Experience
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        formField,
                      }}
                    />
                  )}
                  {bgBox(
                    <BankInfo
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        formField,
                      }}
                    />
                  )}
                  {bgBox(
                    <Contacts
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        formField,
                      }}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Employee;
