import Tasks from "../items/Tasks";

const tasksRoutes = [
  {
    key: "configurations-tasks",
    route: "/account/configurations/tasks/tasks",
    component: <Tasks />,
  },
];

export default tasksRoutes;
