import { useState } from "react";

// @mui material components
import { Container, Grid, AppBar, Tabs, Tab } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// Pricing page components
import PricingCards from "./components/PricingCards";
import PricingTabs from "./components/PricingTabs";

function Pricing() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "yearly") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ textAlign: "center" }}
      >
        <Grid item xs={10} lg={5}>
          <SoftBox mb={1}>
            <SoftTypography variant="h3" color="dark" fontWeight="bold">
              Cambia tu plan ahora
            </SoftTypography>
          </SoftBox>
          <SoftBox>
            <SoftTypography variant="body2" color="dark" fontWeight="regular">
              You have Free Unlimited Updates
              <br />
              and Premium Support on each package.
            </SoftTypography>
          </SoftBox>
          <Grid
            container
            item
            xs={12}
            sm={10}
            md={6}
            lg={7}
            sx={{ mx: "auto" }}
          >
            <PricingTabs tabValue={tabValue} tabHandler={handleSetTabValue} />
          </Grid>
        </Grid>
      </Grid>
      <Container sx={{ mt: 20 }}>
        <PricingCards prices={prices} />
      </Container>
    </>
  );
}

export default Pricing;
