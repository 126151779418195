import { useTranslation } from "react-i18next";

const Dictionary = () => {
  const { t } = useTranslation(["pageRoutes"]);
  const words = {
    pageRoutes: {
      dashboards: t("pageRoutes:dashboards"),
      default: t("pageRoutes:default"),
      pages: t("pageRoutes:pages"),
      account: t("pageRoutes:account"),
      configurations: t("pageRoutes:configurations"),
      userManagment: t("pageRoutes:userManagment"),
      employeeInfo: t("pageRoutes:employeeInfo"),
      accounts: t("pageRoutes:accounts"),
      notifications: t("pageRoutes:notifications"),
      plansBilling: t("pageRoutes:plansBilling"),
      workingHours: t("pageRoutes:workingHours"),
      administrator: t("pageRoutes:administrator"),
      users: t("pageRoutes:users"),
      reports: t("pageRoutes:reports"),
      newUser: t("pageRoutes:new-user"),
      profile: t("pageRoutes:profile"),
      profileOverview: t("pageRoutes:profile-overview"),
      teams: t("pageRoutes:teams"),
      allProjects: t("pageRoutes:all-projects"),
      notifyMe: t("pageRoutes:notify-me"),
      extra: t("pageRoutes:Extra"),
      pricingPage: t("pageRoutes:pricing-page"),
      widgets: t("pageRoutes:widgets"),
      charts: t("pageRoutes:charts"),
      projects: t("pageRoutes:projects"),
      general: t("pageRoutes:general"),
      timeline: t("pageRoutes:timeline"),
      newProject: t("pageRoutes:new-project"),
      overview: t("pageRoutes:overview"),
      referral: t("pageRoutes:referral"),
      authentication: t("pageRoutes:authentication"),
      signIn: t("pageRoutes:sign-in"),
      basic: t("pageRoutes:basic"),
      signUp: t("pageRoutes:sign-up"),
      resetPassword: t("pageRoutes:reset-password"),
      lock: t("pageRoutes:lock"),
      twoStepVerification: t("pageRoutes:2-step-verification"),
      error: t("pageRoutes:error"),
      error404: t("pageRoutes:error-404"),
      error500: t("pageRoutes:error-500"),
      learningCenter: t("pageRoutes:learning-center"),
      cerezaAcademy: t("pageRoutes:cereza-academy"),
      competencyAssessment: t("pageRoutes:competency-assessment"),
      learningMarketplace: t("pageRoutes:learning-marketplace"),
      home: t("pageRoutes:home"),
      keyIndicators: t("pageRoutes:key-indicators"),
      help: t("pageRoutes:help"),
      support: t("pageRoutes:support"),
      settings: t("pageRoutes:settings"),
      editions: t("pageRoutes:editions"),
      customers: t("pageRoutes:customers"),
      billing: t("pageRoutes:billing"),
      iam: t("pageRoutes:iam"),
      discounts: t("pageRoutes:discounts"),
      relations: t("pageRoutes:relations"),
      usage: t("pageRoutes:usage"),
      assessments: t("pageRoutes:assessments"),
      evaluations: t("pageRoutes:evaluations"),
      polls: t("pageRoutes:polls"),
      recruitment: t("pageRoutes:recruitment"),
      workplaceClimate: t("pageRoutes:workplace-climate"),
    },
  };
  return words;
};

export default Dictionary;
