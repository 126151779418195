import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MESSAGE_TYPES, UI_STATES, API_ACTIONS } from "utils/constants";

const initialState = {
  uiState: UI_STATES.IS_VIEWING,
  msgType: MESSAGE_TYPES.INIT,
  apiActions: API_ACTIONS.INIT,
  language: {
    en: false,
    es: true,
    fr: false,
    pt: false,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    setUiState: (state, action) => {
      state.uiState = action.payload;
    },
    message: (state, action) => {
      state.msgType = action.payload;
    },
    setApiActions: (state, action) => {
      state.apiActions = action.payload;
    },
    setCurrentLang: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const {
  setUiState,
  setApiActions,
  message,
  setCurrentLang,
} = uiSlice.actions;

export const uiSelect = (state) => state.ui;

export default uiSlice.reducer;
