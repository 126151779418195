// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid, Card, Tooltip, Icon } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

function UserManagementCard({ title, info, action }) {
  const labels = [];
  const values = [];

  Object.values(info).forEach((el) => labels.push(el.label));
  Object.values(info).forEach((el) => values.push(el.value));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SoftBox key={label} pb={1}>
      <SoftTypography variant="caption" fontWeight="light">
        {label}
      </SoftTypography>
      <SoftTypography
        variant="button"
        component="h1"
        fontWeight="medium"
        color="dark"
      >
        {values[key]}
      </SoftTypography>
    </SoftBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SoftTypography
          variant="button"
          fontWeight="bold"
          color="dark"
          textTransform="capitalize"
        >
          {title}
        </SoftTypography>
        <SoftTypography
          component={Link}
          to={action.route}
          variant="body2"
          color="info"
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
          <SoftTypography variant="caption" color="info">
            &nbsp;Editar
          </SoftTypography>
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox>
          <Grid container>
            <Grid>{renderItems}</Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the UserManagementCard
UserManagementCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserManagementCard;
