import checkout from "./form";

const {
  formField: {
    edition,
    monthlyPrice,
    annualPrice,
    employeeLimit,
    emailLimit,
    spaceLimit,
    adminLimit,
    areasLimit,
    templatesLimit,
    vacanciesLimit,
    currentsLimit,
    interviewsLimit,
    evaluationsLimit,
    emailHelp,
    chatHelp,
    phoneHelp,
    smtpFeature,
    slaHelp,
  },
} = checkout;

const initialValues = {
  [edition.name]: "",
  [monthlyPrice.name]: "",
  [annualPrice.name]: "",
  [employeeLimit.name]: "",
  [emailLimit.name]: "",
  [spaceLimit.name]: "",
  [adminLimit.name]: "",
  [areasLimit.name]: "",
  [templatesLimit.name]: "",
  [vacanciesLimit.name]: "",
  [currentsLimit.name]: "",
  [interviewsLimit.name]: "",
  [evaluationsLimit.name]: "",
  [emailHelp.name]: false,
  [chatHelp.name]: false,
  [phoneHelp.name]: false,
  [smtpFeature.name]: false,
  [slaHelp.name]: "",
};

export default initialValues;
