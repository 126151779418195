// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Badge, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

function PricesInfo({ plans }) {
  const renderPlans = plans.map((plan) => (
    <Grid item xs={12} lg={4} key={plan.label}>
      <Badge
        variant="contained"
        color={plan.color}
        badgeContent={
          <span style={{ fontSize: "9px" }}>
            <strong>{plan.label}</strong>
          </span>
        }
        container
      />
      <SoftBox my={1}>
        <SoftTypography variant="h2" color="white" fontWeight="medium">
          <SoftTypography
            display="inline"
            component="small"
            variant="h2"
            color="white"
            fontWeight="medium"
          >
            {plan.currency}
          </SoftTypography>
          {plan.value}
        </SoftTypography>
      </SoftBox>
    </Grid>
  ));

  return (
    <SoftBox pt={1} pb={3} px={2} textAlign="center">
      <Grid container spacing={3} justifyContent="center">
        {renderPlans}
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the PricesInfo
PricesInfo.propTypes = {
  plans: PropTypes.arrayOf([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default PricesInfo;
