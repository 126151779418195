import { useTranslation } from "react-i18next";

const Dictonary = () => {
  const { t } = useTranslation(['header']);
  const words = {
   header: {
      greeting: t("header:greeting"),
      login: t("header:login"),
      title: t("header:title"),
      // text: t("buildByDevelopers.text"),
      // footer: t("buildByDevelopers.footer"),
    },
    // Agrega aquí todas las palabras que desees exportar
  };
  return words;
};

export default Dictonary;
