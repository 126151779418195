// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// Billing page components
import Bill from "../Bill";


function BillingInformation() {
  return (
    <Card id="billing-information">
      <SoftBox pt={3} px={2}>
        <SoftTypography variant="button" display="block" fontWeight="bold">
          Información de facturación
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
            name="DeSiCi"
            company="DeSiCi"
            email="DeSiCi@correo.com"
            vat="DSCB1235476"
          />
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default BillingInformation;
