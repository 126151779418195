// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "softMui/SoftBox";

// Soft UI Dashboard React components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// WorkingHours page components
import Header from "../components/Header";

// Settings page components
import CurrentPlan from "../components/CurrentPlan";
import ContactDetails from "./components/contactDetails";
import PlansPublicity from "./components/plans-publicity";

function Accounts() {
  return (
    <DashboardLayout>
      <Header />
      <SoftBox my={3}>
        <CurrentPlan />
      </SoftBox>

      <SoftBox mt={4}>
        <Grid container spacing={12}>
          <Grid item xs={12} lg={7}>
            <ContactDetails />
          </Grid>
          <Grid item xs={12} lg={5}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PlansPublicity />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Accounts;
