import { combineReducers } from "@reduxjs/toolkit";

import uiReducers from "redux/slices/ui";
import authUserReducers from "redux/slices/authUser";
import { generalsApi } from "App/apis";

const reducers = combineReducers({
  ui: uiReducers,
  authUser: authUserReducers,
  [generalsApi.reducerPath]: generalsApi.reducer,
});

export default reducers;
