// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// NewUser page components
import FormField from "../FormField";

function Contacts({ formData }) {
  const { values, errors, touched, setFieldValue } = formData;
  const {
    emergencyContactNames,
    emergencyContactSurnames,
    emergencyContactRelationship,
    emergencyContactPhone,
  } = formData.formField;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="bold" color="text">
          Personas de contacto
        </SoftTypography>
      </SoftBox>
      <SoftBox my={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={emergencyContactNames.type}
              label={emergencyContactNames.label}
              name={emergencyContactNames.name}
              value={values.emergencyContactNames}
              placeholder={emergencyContactNames.placeholder}
              error={
                errors.emergencyContactNames && touched.emergencyContactNames
              }
              // success={values.emergencyContactNames.length > 0 && !errors.emergencyContactNames}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={emergencyContactSurnames.type}
              label={emergencyContactSurnames.label}
              name={emergencyContactSurnames.name}
              value={values.emergencyContactSurnames}
              placeholder={emergencyContactSurnames.placeholder}
              error={
                errors.emergencyContactSurnames &&
                touched.emergencyContactSurnames
              }
              // success={values.emergencyContactSurnames.length > 0 && !errors.emergencyContactSurnames}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={emergencyContactRelationship.type}
              label={emergencyContactRelationship.label}
              name={emergencyContactRelationship.name}
              value={values.emergencyContactRelationship}
              placeholder={emergencyContactRelationship.placeholder}
              error={
                errors.emergencyContactRelationship &&
                touched.emergencyContactRelationship
              }
              // success={values.cardNemergencyContactRelationshipumber.length > 0 && !errors.emergencyContactRelationship}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={emergencyContactPhone.type}
              label={emergencyContactPhone.label}
              name={emergencyContactPhone.name}
              value={values.emergencyContactPhone}
              placeholder={emergencyContactPhone.placeholder}
              error={
                errors.emergencyContactPhone && touched.emergencyContactPhone
              }
              // success={values.emergencyContactPhone.length > 0 && !errors.emergencyContactPhone}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Contacts
Contacts.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Contacts;
