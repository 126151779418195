const data = {
  personalInfo: {
    names: { label: "Nombre (s)", value: "Jorge" },
    surnames: { label: "Apellidos", value: "López López" },
    gender: { label: "Género", value: "Masculino" },
    birthday: {
      label: "Fecha de nacimiento",
      value: "23/01/1998",
    },
    civilStatus: { label: "Estado civil", value: "Soltero" },
    socialSecurityNumber: {
      label: "Número de seguridad social",
      value: "23456789",
    },
    curp: { label: "CURP", value: "JLL9876YH879054J" },
    rfc: { label: "RFC", value: "JLL9876YH879054J848757" },
    cellphone: {
      label: "Número celular",
      value: "55 1234 5678",
    },
    phone: {
      label: "Teléfono particular",
      value: "55 1234 5678",
    },
    email: {
      label: "Correo electrónico",
      value: "JorgeLopez@correo.com",
    },
  },
  employeeInfo: {
    dateEntry: {
      label: "Fecha de ingreso",
      value: "23/01/1998",
    },
    department: {
      label: "Departamento",
      value: "Recursos humanos",
    },
    position: {
      label: "Puesto",
      value: "Subgerente",
    },
    reportingPerson: {
      label: "Persona a quien reporta",
      value: "Juan Hernández Hernández",
    },
    contractType: {
      label: "Tipo de contrato",
      value: "Temporal",
    },
    status: {
      label: "Estado del empleado",
      value: "Activo",
    },
    location: {
      label: "Ubicación",
      value: "Ciudad de México",
    },
    checkoutDate: {
      label: "Fecha de salida",
      value: "No aplica",
    },
  },

  // edication
  education: {
    university: {
      label: "Universidad",
      value: "Universidad tecnológica",
    },
    degree: {
      label: "Grado / Diploma",
      value: "Licenciado en administración",
    },
    endDate: {
      label: "Fecha de finalización",
      value: "23/01/1998",
    },
  },

  // experience
  experience: {
    previousCompany: {
      label: "Nombre de la empresa anterior",
      value: "Tecnología e innovación",
    },
    previousPosition: {
      label: "Cargo",
      value: "Subgerente",
    },
    startDate: {
      label: "Fecha desde",
      value: "23/01/1998",
    },
    endDate: {
      label: "Fecha hasta",
      value: "23/01/1998",
    },
    jobDescription: {
      label: "Descripción del empleo",
      value:
        "Asistir al gerente en la organización, planificación e implementación de la estrategia",
    },
    relevant: {
      label: "Relevante",
      value: "No aplica",
    },
  },

  // bank info
  bankInfo: {
    bank: {
        label: "Banco",
        value: "Santander",
    },
    accountCabe: {
        label: "Cuenta clabe",
        value: "12345678003948003947",
    },
    cardNumber: {
        label: "Número de tarjeta",
        value: "2345 6789 5643",
    },
    accountNumber: {
        label: "Número de cuenta",
        value: "123456789012345678",
    },
  },

  // emergency contact
  emergencyContact: {
    names: {
        label: "Nombre (s)",
        value: "Adrian",
    },
    surnames: {
        label: "Apellidos",
        value: "López López",
    },
    relationship: {
        label: "Parentesco",
        value: "Hermano",
    },
    phone: {
        label: "Teléfono",
        value: "55 234 567 89",
    },
  },
};

export default data;
