import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser } from "redux/slices/authUser";
import { useNavigate } from "react-router-dom";

import { userManager } from "./userManager";
import { useGetPersonQuery } from "App/apis";

export const SignInKeyCloak = () => {
  useEffect(() => {
    const login = async () => {
      try {
        await userManager.signinRedirect();
      } catch (error) {
        // TODO: reintentar la redirección en vista de error
        console.error("Failed to authenticate", error);
      }
    };
    login();
  }, []);
};

export const Callback = () => {
  const { accessToken } = useSelector((state) => state.authUser);

  // TODO: este ejemplo muestra cómo saltar una petición
  const { data, error, isLoading, refetch } = useGetPersonQuery("", {
    skip: !accessToken,
  });

  // TODO: aquí debemos integrar el thunk para obtener todos los forms

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then((user) => {
        dispatch(setAuthUser(user.access_token));
        navigate("/dashboards");
      })
      .catch((error) => console.log("Auth error: ", error));
  }, [dispatch, navigate]);

  // TODO: cuando tenemos accesToken ejecutamos refetch para hacer el llamado
  useEffect(() => {
    if (accessToken) {
      refetch().catch((error) => console.log("error", error));
    }
  }, [accessToken, refetch]);
};
