// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// NewUser page components
import FormField from "../FormField";

import SoftSelect from "softMui/SoftSelect";
import SoftDatePicker from "softMui/SoftDatePicker";

function Experience({ formData }) {
  const { values, errors, touched, setFieldValue } = formData;
  const { backCompany, backPosition, birthday, description, relevant } =
    formData.formField;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="button" fontWeight="bold" color="text">
          Experiencia
        </SoftTypography>
      </SoftBox>
      <SoftBox my={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={backCompany.type}
              label={backCompany.label}
              name={backCompany.name}
              value={values.backCompany}
              placeholder={backCompany.placeholder}
              error={errors.backCompany && touched.backCompany}
              // success={values.backCompany.length > 0 && !errors.backCompany}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {backPosition.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={backPosition.name}
                    placeholder={backPosition.placeholder}
                    defaultValue={values.backPosition ?? null}
                    options={[
                      { value: "G", label: "Gerente" },
                      { value: "SG", label: "Subgerente" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {birthday.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker
                    name={birthday.name}
                    placeholder={birthday.placeholder}
                    value={values.birthday}
                    onChange={(newDate) =>
                      setFieldValue(`${birthday.name}`, newDate)
                    }
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={values.description}
              placeholder={description.placeholder}
              error={errors.description && touched.description}
              multiline
              rows={5}
              // success={values.description.length > 0 && !errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              type={relevant.type}
              label={relevant.label}
              name={relevant.name}
              value={values.relevant}
              placeholder={relevant.placeholder}
              error={errors.relevant && touched.relevant}
              // success={values.relevant.length > 0 && !errors.relevant}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Experience
Experience.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Experience;
