import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
};

export const authUser = createSlice({
  name: "authUser",
  initialState: initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const { setAuthUser } = authUser.actions;

export const uiSelect = (state) => state.authUser;

export default authUser.reducer;
