const transactionsData = [
  {
    name: "14/03/2023",
    description: "27 March 2020, at 04:30 AM",
    value: "180",
  },
  {
    name: "14/02/2023",
    description: "27 March 2020, at 04:30 AM",
    value: "180",
  },
  {
    name: "14/01/2023",
    description: "27 March 2020, at 04:30 AM",
    value: "180",
  },
];

export default transactionsData;
