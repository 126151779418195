// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";
// import SoftButton from "components/SoftButton";

// Billing page components
import Transaction from "../Transaction";

import transactionsData from "./data";

// Utils functions
import FormatDate from "utils/DateFormat";

function Transactions() {
    const renderData = transactionsData.map(
      ({ color, name, description, value }) => (
        // todo : se debe evaluar el color con el valor
        <Transaction
          key={name}
          color="success"
          icon="arrow_upward"
          name={FormatDate(name)}
          description={description}
          value={`$${value}`}
        />
      )
    );
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <SoftTypography variant="button" display="block" fontWeight="bold" textTransform="capitalize" color="dark">
          Renovaciones del plan
        </SoftTypography>
        <SoftBox display="flex" alignItems="flex-start">
          <SoftBox color="dark" mr={0.5} lineHeight={0}>
            <Icon color="dark" fontSize="small">
              date_range
            </Icon>
          </SoftBox>
          <SoftTypography variant="button" color="dark" fontWeight="bold">
            2023
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox pt={3} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {renderData}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Transactions;
