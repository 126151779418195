// import { useEffect } from "react";
// import { Link } from "react-router-dom";
// import Dictonary from "./translate/Dictonary";
// import i18next from "i18next";
// import cookies from "js-cookie";
// import { useTranslation } from "react-i18next";
// const languages = [

//   {
//     code: 'en',
//     name: 'English',
//     country_code: 'gb',
//   },
//   {
//     code: 'es',
//     name: 'Español',
//     country_code: 'es',
//   },
// ]

// const Header = () => {
// 	const [t, i18n] = useTranslation('header');
// 	const handleChangeLanguage = (lang) => {
// 		i18n.changeLanguage(lang);
// 	};
// 	const words=Dictonary()
// 	// const { i18n, t } = useTranslation(["common","header"]);

// 	useEffect(() => {
// 		if (localStorage.getItem("i18nextLng")?.length > 2) {
// 			i18next.changeLanguage("en");
// 		}
// 	}, []);

// 	// const handleLanguageChange = (e) => {
// 	// 	i18n.changeLanguage(e.target.value);
// 	// };
// 	const currentLanguageCode = cookies.get('i18next') || 'es'
//   // const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

//   // useEffect(() => {
//   //   console.log('Setting page stuff')
// 	// 	document.body.dir = currentLanguage && currentLanguage.dir ? currentLanguage.dir : 'ltr';
//   //   document.title = t('app_title')
//   // }, [currentLanguage, t])

// 	return (
// 		<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
// 			<Link className="navbar-brand" to="/">
// 			{words.header.login}
// 			</Link>
// 			<h1>{t("header:greeting")}</h1>

// 			<button
// 				className="navbar-toggler"
// 				type="button"
// 				data-toggle="collapse"
// 				data-target="#navbarNav"
// 				aria-controls="navbarNav"
// 				aria-expanded="false"
// 				aria-label="Toggle navigation"
// 			>
// 				<span className="navbar-toggler-icon"></span>
// 			</button>
// 			<button onClick={()=>handleChangeLanguage('en')}>EN</button>
// 			<button onClick={()=>handleChangeLanguage('es')}>ES</button>
// 			<div className="collapse navbar-collapse" id="navbarNav">
// 				<ul className="navbar-nav ml-auto">
// 				{languages.map(({ code, name, country_code }) => (
//                 <li key={country_code}>
//                   <a
//                     href="#"

//                     onClick={() => {
// 											console.log('cambiado a ', code )

//                       i18next.changeLanguage(code)
//                     }}
//                   >
//                     <span

//                     ></span>
//                     {name}
//                   </a>
//                 </li>
//               ))}
// 					<li className="nav-item">
// 						{/* <select
// 							// className="nav-link bg-dark border-0 ml-1 mr-2"
// 							value={localStorage.getItem("i18nextLng")}
// 							onChange={handleLanguageChange}
// 						>
// 							<option value="en">English</option>
// 							<option value="fr">Français</option>
// 							<option value="es">Español</option>
// 						</select> */}
// 					</li>
// 					<li className="nav-item ml-2">
// 						<Link className="nav-link" to="/profile">
// 							{words.header.login}
// 							{/* {words.header.login} */}
// 							<br />
// 							{/* {words.header.title} */}
// {}
// 						</Link>
// 					</li>
// 				</ul>
// 			</div>
// 		</nav>
// 	);
// };

// export default Header;

////

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Dictonary from "./translate/Dictonary";
import i18n from "i18n"; // Importar i18n antes que useTranslation

import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
];
const handleChangeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

const Header = () => {
  const { t } = useTranslation("header"); // Ajustar el orden de las variables
  const words = Dictonary();

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "es";

  return (
    <></>
    // <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    //   <Link className="navbar-brand" to="/">
    //     {/* {words.header.login} */}
    //   </Link>
    //   <h1>{t("header:greeting")}</h1>
		// 	<br />
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarNav"
    //     aria-controls="navbarNav"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <span className="navbar-toggler-icon"></span>
    //   </button>
    //   <button onClick={() => handleChangeLanguage("en")}>EN</button>
    //   <button onClick={() => handleChangeLanguage("es")}>ES</button>
    //   {/* <div className="collapse navbar-collapse" id="navbarNav">
    //     <ul className="navbar-nav ml-auto">
    //       {languages.map(({ code, name, country_code }) => (
    //         <li key={country_code}>
    //           <a
    //             href="#"
    //             onClick={() => {
    //               console.log("cambiado a ", code);
    //               i18n.changeLanguage(code);
    //               handleChangeLanguage(code);
    //             }}
    //           >
    //             <span></span>
    //             {name}
    //           </a>
    //         </li>
    //       ))}
    //       <li className="nav-item ml-2">
    //         <Link className="nav-link" to="/profile">
    //           {words.header.title}
    //         </Link>
    //       </li>
    //     </ul>
    //   </div> */}
    // </nav>
  );
};

export default Header;
