/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import { useLocation, useNavigate } from "react-router-dom";

///icons

import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import AssessmentIcon from "@mui/icons-material/Assessment";
// Soft UI Dashboard PRO React icons
import Shop from "components/Icons/Shop";
import Office from "components/Icons/Office";
import SettingsIcon from "components/Icons/Settings";
import WorkingHours from "App/layouts/pages/account/working-hours";
import PlansAndBilling from "App/layouts/pages/account/plans-billing";
import Accounts from "App/layouts/pages/account/accounts";
import AccountNotifications from "App/layouts/pages/account/notifications";
import Employee from "App/layouts/pages/employee";
import UserManagement from "App/layouts/pages/account/userManagement";
//chequear este import
// import Configurations from "App/layouts/pages/account/configurations";

import Dictionary from "translate.route/Dictionary";

import KeyIndicators from "App/Modules/Home/KeyIndicators";
import CerezaAcademy from "App/Modules/LearningCenter/CerezaAcademy";
import CompetencyAssessment from "App/Modules/LearningCenter/CompetencyAssessment";
import LearningMarketplace from "App/Modules/LearningCenter/LearningMarketplace";
import Support from "App/Modules/Help/Support";
import Editions from "App/Modules/Settings/Editions";
import Configurations from "App/Modules/Settings/Configurations";
import Evaluations from "App/Modules/Assesments/Evaluations";
import Polls from "App/Modules/Assesments/Polls";
import Recruitment from "App/Modules/Assesments/Recruitment";
import WorkplaceClimate from "App/Modules/Assesments/WorkplaceClimate";
import Billing from "App/Modules/Customers/Billing";
import Discounts from "App/Modules/Customers/Discounts";
import Iam from "App/Modules/Customers/IAM";
import Relations from "App/Modules/Customers/Relations";
import Usage from "App/Modules/Customers/Usage";
export default function rutas() {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname === "/Customers/Relations") {
    window.open(
      "https://crm.zoho.com/crm/org809430210/tab/Home/begin",
      "_blank"
    );
    navigate("/Customers/accounts");
  }
  if (location.pathname === "/Customers/Billing") {
    window.open("https://dashboard.stripe.com/dashboard", "_blank");
    navigate("/Customers/accounts");
  }
  if (location.pathname === "/Customers/IAM") {
    window.open("https://iam.cereza.dev/admin/", "_blank");
    navigate("/Customers/accounts");
  }
  const words = Dictionary();

  const routes = [
    {
      type: "collapse",
      name: words.pageRoutes.home,
      key: "home",
      icon: <Shop size="12px" color="white" />,
      collapse: [
        {
          type: "collapse",
          name: words.pageRoutes.account,
          key: "account",
          icon: <PersonIcon size="12px" />,
          collapse: [
            {
              name: words.pageRoutes.configurations,
              key: "configurations",
              route: "/home/account/configurations",
              component: <Configurations />,
            },
            {
              name: words.pageRoutes.userManagment,
              key: "user-management",
              route: "/home/account/user-management",
              component: <UserManagement />,
            },
            {
              name: words.pageRoutes.employeeInfo,
              key: "edit-employee-info",
              route: "/home/account/edit-employee-info",
              component: <Employee />,
            },
            {
              name: words.pageRoutes.accounts,
              key: "accounts",
              route: "/home/account/accounts",
              component: <Accounts />,
            },
            {
              name: words.pageRoutes.notifications,
              key: "notifications",
              route: "/home/account/notifications",
              component: <AccountNotifications />,
            },
            {
              name: words.pageRoutes.plansBilling,
              key: "plans-billing",
              route: "/home/account/plans-billing",
              component: <PlansAndBilling />,
            },
            {
              name: words.pageRoutes.workingHours,
              key: "working-hours",
              route: "/home/account/working-hours",
              component: <WorkingHours />,
            },
          ],
        },
        {
          name: words.pageRoutes.keyIndicators,
          key: "key-indicators",
          route: "/home/key-indicators",
          component: <KeyIndicators />,
        },
      ],
    },
    {
      type: "collapse",
      name: words.pageRoutes.learningCenter,
      key: "learning-center",
      icon: <SchoolIcon size="12px" color="white" />,
      collapse: [
        {
          name: words.pageRoutes.cerezaAcademy,
          key: "cereza-academy",
          route: "/learning-center/cereza-academy",
          component: <CerezaAcademy />,
        },
        {
          name: words.pageRoutes.competencyAssessment,
          key: "competency-assessment",
          route: "/learning-center/competency-assessment",
          component: <CompetencyAssessment />,
        },
        {
          name: words.pageRoutes.learningMarketplace,
          key: "learning-marketplace",
          route: "/learning-center/learning-marketplace",
          component: <LearningMarketplace />,
        },
      ],
    },

    {
      type: "collapse",
      name: words.pageRoutes.help,
      key: "help",
      icon: <HelpCenterIcon size="12px" color="white" />,
      collapse: [
        {
          name: words.pageRoutes.support,
          key: "support",
          route: "/help/support",
          component: <Support />,
        },
      ],
    },
    {
      type: "collapse",
      name: words.pageRoutes.settings,
      key: "settings",
      icon: <SettingsIcon size="12px" color="white" />,
      collapse: [
        {
          name: words.pageRoutes.configurations,
          key: "configurations",
          route: "/settings/configurations",
          component: <Configurations />,
        },
        {
          name: words.pageRoutes.editions,
          key: "editions",
          route: "/settings/editions",
          component: <Editions />,
        },
      ],
    },
    {
      type: "collapse",
      name: words.pageRoutes.assessments,
      key: "evaluations",
      icon: <AssessmentIcon size="12px" color="white" />,
      collapse: [
        {
          name: words.pageRoutes.evaluations,
          key: "evaluations",
          route: "/evaluations/evaluations",
          component: <Evaluations />,
        },
        {
          name: words.pageRoutes.polls,
          key: "editions",
          route: "/evaluations/editions",
          component: <Polls />,
        },
        {
          name: words.pageRoutes.recruitment,
          key: "recruitment",
          route: "/evaluations/recruitment",
          component: <Recruitment />,
        },
        {
          name: words.pageRoutes.workplaceClimate,
          key: "workplace-climate",
          route: "/evaluations/workplace-climate",
          component: <WorkplaceClimate />,
        },
      ],
    },
    {
      type: "collapse",
      name: words.pageRoutes.customers,
      key: "customers",
      icon: <Office size="12px" color="white" />,
      collapse: [
        {
          name: words.pageRoutes.accounts,
          key: "accounts",
          route: "/customers/accounts",
          component: <Accounts />,
        },

        {
          name: words.pageRoutes.billing,
          key: "billing",
          route: "/customers/billing",
          component: <Billing />,
        },
        {
          name: words.pageRoutes.discounts,
          key: "discounts",
          route: "/customers/discounts",
          component: <Discounts />,
        },
        {
          name: words.pageRoutes.iam,
          key: "iam",
          route: "/customers/iam",
          component: <Iam />,
        },
        {
          name: words.pageRoutes.relations,
          key: "relations",
          route: "/customers/relations",
          component: <Relations />,
        },
        {
          name: words.pageRoutes.usage,
          key: "usage",
          route: "/customers/usage",
          component: <Usage />,
        },
      ],
    },
    { type: "divider", key: "divider-projects" },
  ];
  return routes;
}
