import React, { Suspense } from "react";
import { store } from "redux/store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
// import { TransalateProvider } from "translateContext/TranslateContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          {/* <TransalateProvider> */}
            <SoftUIControllerProvider>
              <App />
            </SoftUIControllerProvider>
          {/* </TransalateProvider> */}
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
