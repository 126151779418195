import { store } from "redux/store";
import { getTenant, CLIENT_ID } from "utils";

export const getAuthToken = () => {
  const state = store.getState();
  const { accessToken } = state.authUser;
  if (accessToken) {
    return accessToken;
  }
  return null;
};

export const prepareHeaders = (headers, { getState }) => {
  headers.set("Content-Type", "application/json");
  headers.set("Authorization", `Bearer ${getAuthToken()}`);
  headers.set("Client", CLIENT_ID);
  headers.set("Realm", getTenant());
  return headers;
};

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const RTKBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  mode: "cors",
  prepareHeaders: prepareHeaders,
});
