// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "softMui/SoftBox";
import SoftTypography from "softMui/SoftTypography";

// Settings page components
import FormField from "App/layouts/pages/account/components/FormField";

// TODO : generar formulario dinámico

function ContactDetailsForm() {
  return (
    <SoftBox component="form" pb={3} px={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="nombre (s)"
            placeholder="DeSiCi"
            inputProps={{ type: "text" }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="apellidos"
            placeholder="Apellidos"
            inputProps={{ type: "text" }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="correo electrónico"
            placeholder="DeSiCi@correo.com"
            inputProps={{ type: "email" }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="teléfono"
            placeholder="55 647 890 45"
            inputProps={{ type: "text" }}
          />
        </Grid>
        <Grid item xs={12}>
          <SoftTypography
            variant="caption"
            display="block"
            sx={{ mb: 0, ml: 0.5 }}
          >
            <strong>Enviar factura a:</strong>
          </SoftTypography>
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="correo electrónico"
            placeholder="DeSiCi@correo.com"
            inputProps={{ type: "email" }}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default ContactDetailsForm;
